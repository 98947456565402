import { getSiteLocale, getViewMode } from './index';
import type { ExperimentsBag } from '@wix/wix-experiments';
import { getCurrentSiteUser } from './current-site-user';
import { getBiToken } from '../getBiToken';
import { getVideosCount } from '../getVideosCount';

import DEVICE_TYPES from '../../constants/device-types';
import { isV3ToV2MappingAPIEnabled } from '@wix/wix-vod-shared/common';
import { getWidgetData, WidgetData } from './getWidgetData';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { FedopsInteractionsNames } from '../../constants/fedops-interaction-names';
import { DEMO_CHANNEL_ID } from '@wix/wix-vod-constants/env';
import { getWidgetWarmupData, setWidgetWarmupData } from './warmupData';

interface GetHydratedSourceOptions {
  experiments: ExperimentsBag;
  isModal: boolean;
  translations: Record<string, string>;
}
export type HydratedSource = Awaited<ReturnType<typeof getHydratedSource>>;

export async function getHydratedSource(
  {
    wixCodeApi,
    platformAPIs,
    appParams,
    compId,
    config,
    essentials: { httpClient },
  }: ControllerParams['controllerConfig'],
  flowAPI: ControllerParams['flowAPI'],
  { experiments, isModal, translations }: GetHydratedSourceOptions,
) {
  const viewMode = getViewMode(wixCodeApi);
  const siteOwnerId = platformAPIs.bi?.ownerId;
  const { instanceId, instance } = appParams;
  const __SITE_URL__ = wixCodeApi.location.baseUrl;
  const __CURRENT_SITE_USER__ = await getCurrentSiteUser(
    wixCodeApi.user.currentUser,
  );
  const styleParams = config.style.styleParams;
  const siteLocale = getSiteLocale(wixCodeApi);
  const fullSiteUrl = wixCodeApi.location.url; // full url
  const deviceType = wixCodeApi.window.formFactor.toLowerCase();
  const biToken = getBiToken(instance, platformAPIs);
  const metaSiteId = platformAPIs.bi?.metaSiteId;
  const templateMetaSiteId = (platformAPIs.bi as any)?.templateMetaSiteId;

  let widgetData: WidgetData = {} as WidgetData; // we need to understand what to do when we couldn't fetch it, why we return an empty object

  const v3ToV2MappingAPIEnabled = isV3ToV2MappingAPIEnabled();

  const sendWidgetDataInteraction = () => {
    flowAPI.fedops.interactionStarted(FedopsInteractionsNames.GET_WIDGET_DATA);
    const interactionName = v3ToV2MappingAPIEnabled
      ? FedopsInteractionsNames.GET_WIDGET_DATA_V3_MAPPING
      : FedopsInteractionsNames.GET_WIDGET_DATA_V2;

    flowAPI.fedops.interactionStarted(interactionName);
    return () => {
      flowAPI.fedops.interactionEnded(FedopsInteractionsNames.GET_WIDGET_DATA);
      flowAPI.fedops.interactionEnded(interactionName);
    };
  };
  if (!isModal) {
    const sendInteractionEnded = sendWidgetDataInteraction();

    const { baseUrl } = wixCodeApi.location;

    const videosCount = getVideosCount({
      isMobile: deviceType === DEVICE_TYPES.MOBILE,
      styleParams,
    });
    try {
      // TODO: styleParams.fonts typings are wrong, the channelId and videoId are coming as string values
      const fonts = (styleParams.fonts || {}) as unknown as Record<
        string,
        string
      >;
      // demo channel id – is what we fall back to anyway
      // https://github.com/wix-private/wix-vod/blob/master/wix-vod-widget/src/components/WixVideo/Widget/old/redux/reducers/current-channel-id.ts#L30
      const { channelId = DEMO_CHANNEL_ID, videoId } = fonts;
      const warmupData = getWidgetWarmupData(wixCodeApi, compId);
      widgetData =
        warmupData ??
        (await getWidgetData({
          queryParams: {
            channelId,
            videoId,
            instance,
            videosCount,
            metaSiteId,
            locale: siteLocale,
            apiVersion: v3ToV2MappingAPIEnabled ? 'v3v2mapping' : 'v2',
          },
          translations,
          httpClient,
          baseUrl,
          viewMode: wixCodeApi.window.viewMode,
        }));
      setWidgetWarmupData(wixCodeApi, compId, widgetData);

      sendInteractionEnded();
    } catch (e: any) {
      flowAPI.reportError(new Error('Error getting widget data', { cause: e }));
    }
  }

  return {
    siteOwnerId,
    instanceId,
    instance,
    viewMode,
    appSettings: styleParams,
    compId,
    metaSiteId,
    templateMetaSiteId,
    widgetData,
    __SITE_URL__,
    __CURRENT_SITE_USER__,
    __EXPERIMENTS__: experiments,
    siteLocale,
    fullSiteUrl,
    deviceType,
    biToken,
  };
}
