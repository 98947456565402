import { IWixWindow } from '@wix/yoshi-flow-editor';
import {
  PublicChannelItem,
  YoutubeChannelVideosResponse,
  VideoListResponse,
  YoutubeVideo,
} from '@wix/wix-vod-api-site-based/public';
import type { IHttpClient } from '@wix/http-client';
import { getPublicApi } from '../../api/public';
import { MEDIA_TYPES, URL_TYPES } from '@wix/wix-vod-constants/common';
// todo: this is a type from private, we shouldn't use anything from private in widget
import type { VideoResponse } from '@wix/wix-vod-api-site-based/dist/src/private/services/video/video.types';
import { isWixCodeSEOEnabled } from '@wix/wix-vod-shared/common';

type ViewMode = IWixWindow['viewMode']; // can not import the proper type;

type ApiVersion = 'v2' | 'v3v2mapping';

interface BaseWidgetData {
  apiVersion: ApiVersion;
  __TRANSLATIONS__: Record<string, string>;
}

interface BaseWidgetDataV2<A extends ApiVersion = 'v2'> extends BaseWidgetData {
  apiVersion: A;
  __CHANNEL__: PublicChannelItem;
}

interface SingleVideoWidgetDataV2<
  T extends VideoResponse = VideoResponse,
  A extends ApiVersion = 'v2',
> extends BaseWidgetDataV2<A> {
  isSingleVideo: true;
  __SINGLE_VIDEO__: VideoResponse | YoutubeVideo | undefined;
}

interface ChannelWidgetDataV2<
  T extends VideoResponse = VideoResponse,
  A extends ApiVersion = 'v2',
> extends BaseWidgetDataV2<A> {
  isSingleVideo: false;
  __VIDEOS__: VideoListResponse | YoutubeChannelVideosResponse;
}

export type WidgetDataV2 = SingleVideoWidgetDataV2 | ChannelWidgetDataV2;

interface V3V2MappingVideoResponse extends VideoResponse {
  content_url: string;
}

export type WidgetDataV3V2Mapping =
  | SingleVideoWidgetDataV2<V3V2MappingVideoResponse, 'v3v2mapping'>
  | ChannelWidgetDataV2<V3V2MappingVideoResponse, 'v3v2mapping'>;

interface GetWidgetDataQueryParams {
  channelId: string;
  videoId?: string;
  instance: string;
  locale?: string;
  videosCount: number;
  apiVersion: ApiVersion;
  metaSiteId?: string;
}

interface GetWidgetDataOptions {
  baseUrl: string;
  viewMode: ViewMode;
  httpClient: IHttpClient;
  translations: Record<string, string>;
  queryParams: GetWidgetDataQueryParams;
}

const getWidgetDataV2 = async ({
  queryParams,
  translations,
}: GetWidgetDataOptions): Promise<WidgetDataV2> => {
  const { channelId, videoId, videosCount } = queryParams;
  const publicApi = getPublicApi();
  const channel = (await publicApi.oldPublicChannelService.getInfo(channelId))
    .data;

  const withPlayInfo = isWixCodeSEOEnabled();

  const baseWidgetData: BaseWidgetDataV2 = {
    apiVersion: 'v2',
    __TRANSLATIONS__: translations,
    __CHANNEL__: channel,
  };

  if (videoId) {
    const requestOptions = withPlayInfo
      ? {
          params: {
            with_play_info: true,
          },
        }
      : undefined;
    const singleVideo = (
      await publicApi.oldPublicChannelVideos.getChannelVideoById(
        channelId,
        videoId,
        requestOptions,
      )
    ).data;
    return {
      ...baseWidgetData,
      __SINGLE_VIDEO__: singleVideo,
      isSingleVideo: true,
    };
  }

  const videos = channel.external_id
    ? (
        await publicApi.oldYoutubeService.getChannelVideos({
          externalId: channel.external_id,
          urlType: channel.url_type as URL_TYPES.CHANNEL | URL_TYPES.PLAYLIST,
          itemsCount: videosCount,
        })
      ).data
    : (
        await publicApi.oldPublicChannelVideos.getList({
          channelId,
          paging: {
            size: videosCount,
          },
          mediaType: MEDIA_TYPES.SECURE_VIDEO,
          withPlayInfo,
        })
      ).data;
  return {
    ...baseWidgetData,
    __VIDEOS__: videos ?? { items: [] },
    isSingleVideo: false,
  };
};

const getWidgetDataV3V2Mapping = async (
  options: GetWidgetDataOptions,
): Promise<WidgetDataV3V2Mapping> => ({
  ...(await getWidgetDataV2(options)),
  apiVersion: 'v3v2mapping',
});

export type WidgetData = WidgetDataV2 | WidgetDataV3V2Mapping;

export const getWidgetData = async (
  options: GetWidgetDataOptions,
): Promise<WidgetData> => {
  const { apiVersion } = options.queryParams;
  switch (apiVersion) {
    case 'v2':
      return getWidgetDataV2(options);
    case 'v3v2mapping':
      return getWidgetDataV3V2Mapping(options);
  }
};
